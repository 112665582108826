<template>
  <v-container fluid fill-height justify="center">
    <v-row justify="center" class="pa-0 pt-6">
      <v-col cols="12" sm="10" md="8">
        <v-row class="pa-0">
          <v-col v-for="(child, index) in items" :key="index" cols="12" md="4" sm="6">
            <v-card elevation="10">
              <v-list-item three-line :to="{ name: child.to,params: child.params}">
                <v-avatar rounded class="ma-3">
                  <v-icon size="50" color="#CAAC79">
                    {{ child.icon }}
                  </v-icon>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title class="pa-0" v-text="child.name" />
                  <v-list-item-subtitle class="pa-0">
                    <small>{{ child.description }}</small>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
export default {
  components: {},
  data: () => ({
    categories: [],
    menu: {
      Digitador: [
        {
          id: 43,
          name: "Editar menús",
          description: "Creear, editar o eliminar Productos del menú",
          icon: "mdi-silverware-fork-knife",
          to: "QR4",
          color: "warning",
        },
      ],
      QR4: [
        {
          id: 10,
          name: "Restaurante",
          description: "Editar información de conatcto",
          icon: "mdi-note-edit-outline",
          to: "Chef_Store",
          color: "warning",
        },
        {
          id: 41,
          name: "Editar menú",
          description: "Creear, editar o eliminar Productos del menú",
          icon: "mdi-note-edit-outline",
          to: "Chef_plates",
          color: "warning",
        },
        {
          id: 42,
          name: "Ordenes",
          description: "Gestión de ordenes recibidas en línea o desde el POS",
          icon: "mdi-room-service-outline",
          to: "Chef_orders",
          color: "warning",
        },
        {
          id: 43,
          name: "Domicilios",
          description: "Seguimiento de los despachos a domicilio.",
          icon: "mdi-moped-outline",
          to: "Chef_delivery",
          color: "warning",
        },
        {
          id: 91,
          name: "POS - Punto de venta",
          description: "Terminal punto de venta",
          icon: "mdi-map-marker-radius",
          color: "info",
          to: "chef_tpv",
        },
        {
          id: 21,
          name: "Proveedores",
          description:
            "Creear, editar o eliminar contactos de proveedores mayoristas",
          icon: "mdi-store-outline",
          color: "warning",
          to: "Chef_Supplier_Catalogue",
        },
        // {
        //   id: 98,
        //   name: "Configuración",
        //   description:
        //     "Configurar impresoras BT or LAN",
        //   icon: "mdi-printer-settings",
        //   to: "Settings",
        //   color: "info",
        // },
        // {
        //   id: 44,
        //   name: "Análsis de Costos por plato",
        //   description: "Creear, editar o eliminar Análsis de costos",
        //   icon: "mdi-silverware-fork-knife",
        //   to: "Chef_cost_analysis",
        //   color: "warning",
        // },
      ],
      Promotor: [
        {
          id: 42,
          name: "Restaurantes",
          description: "Creear, editar o eliminar Restaurantes",
          icon: "mdi-silverware-fork-knife",
          to: "Pos",
          color: "warning",
        },
        {
          id: 88,
          name: "Tarjeta Digital",
          description: "QR con los datos de contacto personal.",
          icon: "mdi-card-account-details-outline",
          to: "BusinessCard",
          color: "info",
        },
      ],
      Supervisor: [
        {
          id: 42,
          name: "Restaurantes",
          description: "Creear, editar o eliminar Restaurantes",
          icon: "mdi-silverware-fork-knife",
          to: "Pos",
          color: "warning",
        },
        {
          id: 88,
          name: "Tarjeta Digital",
          description: "QR con los datos de contacto personal.",
          icon: "mdi-card-account-details-outline",
          to: "BusinessCard",
          color: "info",
        },
        {
          id: 45,
          name: "Nuestra gente",
          description:
            "Creear, editar o eliminar colaboradores, alidos, proveedores logísticos",
          icon: "mdi-account-multiple-outline",
          to: "Empleados",
          color: "info",
        },
      ],
      Providers: [
        {
          id: 21,
          name: "Proveedores",
          description:
            "Creear, editar o eliminar contactos de proveedores mayoristas",
          icon: "mdi-store-outline",
          color: "warning",
          to: "Proveedores",
        },
        {
          id: 46,
          name: "Catalogo Proveedores",
          description:
            "Creear, editar o eliminar lista de precios de mayoristas",
          icon: "mdi-file-document-edit-outline",
          to: "Material",
          color: "warning",
        },
      ],
      Supplier: [
        {
          id: 1,
          name: "Lista de Precios",
          description:
            "Creear, editar o eliminar productos de la lista de precios.",
          icon: "mdi-store-outline",
          color: "warning",
          to: "Proveedores",
        },
        {
          id: 2,
          name: "Clientes",
          description:
            "Creear, editar o eliminar clientes.",
          icon: "mdi-file-document-edit-outline",
          to: "Material",
          color: "warning",
        },
        {
          id: 3,
          name: "Pedidos",
          description: "Creear, editar o eliminar pedidos.",
          icon: "mdi-file-document-edit-outline",
          to: "Material",
          color: "warning",
        },
        {
          id: 4,
          name: "Mi perfil",
          description:
            "Editar perfil, logo, información de contacto.",
          icon: "mdi-file-document-edit-outline",
          to: "Material",
          color: "warning",
        },
      ],
      Client: [
        {
          id: 3,
          name: "Mis pedidos",
          description: "Creear, editar o eliminar pedidos.",
          icon: "mdi-file-document-edit-outline",
          to: "Material",
          color: "warning",
        },
        {
          id: 1,
          name: "Lista de Precios",
          description:
            "Creear, editar o eliminar productos de la lista de precios.",
          icon: "mdi-store-outline",
          color: "warning",
          to: "Proveedores",
        },
        {
          id: 2,
          name: "Proveedores",
          description: "Creear, editar o eliminar proveedores.",
          icon: "mdi-file-document-edit-outline",
          to: "Material",
          color: "warning",
        },

        {
          id: 4,
          name: "Mi perfil",
          description:
            "Editar perfil, logo, información de contacto.",
          icon: "mdi-file-document-edit-outline",
          to: "Material",
          color: "warning",
        },
      ],
      Sales: [
        {
          id: 42,
          name: "Restaurantes",
          description: "Creear, editar o eliminar Restaurantes",
          icon: "mdi-silverware-fork-knife",
          to: "Sales_stores",
          color: "warning",
        },
        {
          id: 87,
          name: "Mapa",
          description:
            "Mapa de la georeferenciación de restaurantes y proveedores.",
          icon: "mdi-map-marker-radius",
          color: "info",
          to: "StoresMap",
        },
      ],
      Owner: [
        {
          id: 0,
          name: "DashBoard",
          description: "Indicadores de la operación",
          icon: "mdi-finance",
          color: "info",
          to: "DashBoard",
        },
        {
          id: 21,
          name: "Proveedores",
          description:
            "Creear, editar o eliminar contactos de proveedores mayoristas",
          icon: "mdi-store-outline",
          color: "warning",
          to: "Proveedores",
        },
        {
          id: 46,
          name: "Catalogo Proveedores",
          description:
            "Creear, editar o eliminar lista de precios de mayoristas",
          icon: "mdi-file-document-edit-outline",
          to: "Material",
          color: "warning",
        },
        {
          id: 42,
          name: "Restaurantes",
          description: "Creear, editar o eliminar Restaurantes",
          icon: "mdi-silverware-fork-knife",
          to: "Pos",
          color: "warning",
        },
        {
          id: 43,
          name: "Editar menús",
          description: "Creear, editar o eliminar Productos del menú",
          icon: "mdi-silverware-fork-knife",
          to: "QR4",
          color: "warning",
        },

        {
          id: 45,
          name: "Nuestra gente",
          description:
            "Creear, editar o eliminar colaboradores, alidos, proveedores logísticos",
          icon: "mdi-account-multiple-outline",
          to: "Empleados",
          color: "info",
        },
        {
          id: 44,
          name: "Admin Usuarios",
          description:
            "Creear, editar o eliminar cuentas de acceso a la plataforma.",
          icon: "mdi-account-lock-outline",
          to: "Usuarios",
          color: "info",
        },

        {
          id: 87,
          name: "Mapa",
          description:
            "Mapa de la georeferenciación de restaurantes y proveedores.",
          icon: "mdi-map-marker-radius",
          color: "info",
          to: "StoresMap",
        },
        {
          id: 88,
          name: "Tarjeta Digital",
          description: "QR con los datos de contacto personal.",
          icon: "mdi-card-account-details-outline",
          to: "BusinessCard",
          color: "info",
        },
        {
          id: 90,
          name: "Donde estamos?",
          description: "Donde esta nuestra gente.",
          icon: "mdi-map-marker-radius",
          color: "info",
          to: "mypeople",
        },
        {
          id: 91,
          name: "TPV",
          description: "Terminal punto de venta",
          icon: "mdi-map-marker-radius",
          color: "info",
          to: "tpv",
        },
      ],
      Chef: [
        {
          id: 46,
          name: "Catalogo Proveedores",
          description:
            "Creear, editar o eliminar lista de precios de mayoristas",
          icon: "mdi-file-document-edit-outline",
          to: "Material",
          color: "warning",
        },
        {
          id: 43,
          name: "Editar menús",
          description: "Creear, editar o eliminar Productos del menú",
          icon: "mdi-silverware-fork-knife",
          to: "Chef_plates",
          color: "warning",
        },
        {
          id: 43,
          name: "Análsis de Costos por plato",
          description: "Creear, editar o eliminar Análsis de costos",
          icon: "mdi-silverware-fork-knife",
          to: "Chef_cost_analysis",
          color: "warning",
        },
      ],
      Trout: [
        {
          id: 70,
          name: "Registros",
          icon: "mdi-fish",
          to: "FishEvents",
          color: "#CDDC39",
        },
        {
          id: 71,
          name: "Documentos",
          icon: "mdi-fish-off",
          to: "FishEvents",
          color: "#CDDC39",
        },
        {
          id: 72,
          name: "Limpieza tanques",
          icon: "mdi-vacuum-outline",
          color: "#FFCA28",
          to: "FishEvents",
        },
        {
          id: 73,
          name: "Calculadora",
          icon: "mdi-calculator",
          color: "#FFCA28",
          to: "FishEvents",
        },
      ],
    },
    items: [],
  }),
  methods: {
    openLink(e) {
      console.log(e);
      this.$router.push("/" + e + "/");
    },
    get_menu() {
      this.loading_status = true;
      var qry = {
        account: window.company.code,
        table: "fish_docs",
        filters: [
          {
            field: "account",
            operator: "=",
            value: window.company.code,
          },
        ],
      };
      webserver("get_table", qry, (data) => {
        this.loading_status = false;
        data.forEach((rg) => {
          rg.to = "FishEvents";
          rg.params = { 'code': rg.code,name: rg.name };
        });

        this.menu.Trout = data;
        this.items = this.menu[this.$store.state.profile.user_access];
      });
    },
    get_items() {
      this.items_bygroup = null;
      var qry = {
        account: this.$store.state.profile.account,
        table: "stores",
        filters: [],
      };
      if (
        this.$store.state.profile.user_access === "Sales" ||
        this.$store.state.profile.user_access === "Promotor"
      ) {
        qry.filters.push({
          field: "agent",
          operator: "=",
          value: this.$store.state.profile.name,
        });
      }
      this.loading_status = true;
      webserver("get_table", qry, (data) => {
        this.loading_status = false;
        data.forEach((rg) => {
          rg.date = new Date(rg.issue_date).toISOString();
          rg.qty = 1;
        });

        var now = new Date();
        var fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 30);

        var lastMonth = [];
        for (var d = new Date(fromDate); d <= now; d.setDate(d.getDate() + 1)) {
          var dtom = new Date(d);
          lastMonth.push(dtom.toISOString());
        }

        var result = [];
        data.reduce(function (res, value) {
          if (!res[value.date]) {
            res[value.date] = { Id: value.date, qty: 0 };
            result.push(res[value.date]);
          }
          res[value.date].qty += value.qty;
          return res;
        }, {});
      });
    },
  },
  mounted() {
    this.get_menu();
  },
  watch: {
    "$store.getters.profile"(newVal) {
      if (newVal) {
        this.items = this.menu[newVal.user_access];
        // console.log(this.items);
        // this.categories = Object.keys(this.items);
        //console.log(newVal);
      }
    },
  },
};
</script>

<style>
.v-main {
  padding: 64px 0px 0px !important;

}
</style>